import { INavData } from '@coreui/angular';

export const navMaterilaItemsEng: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',

  },
  // {
  //   name: 'User',
  //   class: 'module-nav',
  //   children: [
  //     {
  //       name: 'Employee',
  //       url: '/master/employee',
  //       icon: 'icon-user'
  //     },
  //     // {
  //     //   name: 'Students',
  //     //   url: '/master/users',
  //     //   icon: 'icon-cursor'
  //     // },

  //     {
  //       name:'Subscribed-Student',
  //       url: '/master/subscribed-user',
  //       icon: 'icon-people',
  //     },
  //     {
  //       name: 'Register-user',
  //       url: '/master/register-user',
  //       icon: 'icon-people',
  //     },

  //   ]
  // },

  {
    name: 'All applications',
    icon: 'icon-cursor',

    class: 'module-nav',
    children :[
      {
        name: 'Job ',
        url: '/master/job-apply',
        icon: 'icon-people'
      },
      {
        name: 'Internship',
        url: '/master/internship-apply',
        icon: 'icon-support'
      },
    ]

  },
  // {
  //   name:'Role',
  //   class:'module-nav',
  //   children: [
  //     {
  //       name: 'Rights',
  //       url: '/master/rights',
  //       icon: 'icon-cursor'
  //       }
  //   ]
  // },
  {
    name: ' Post a new job ',
    icon: 'icon-cursor',
    class: 'module-nav',
    children:[
      {
        name: 'Manage Jobs',
        url: '/master/placement',
        icon: 'icon-envelope-letter',
      },
      {
        name: 'Manage Internship',
        url: '/master/internship',
        icon: 'icon-puzzle',
      },

    ],
   },
   {
    name: ' Quiz',
    icon: 'icon-cursor',
    class: 'module-nav',
    children:[
      //   {
      //     name: 'Question Set',
      //     url: '/master/queationset',
      //     icon: 'icon-cursor',
      //  },
      {
        name: 'Assessment',
        url: '/master/assessment',
        icon: 'icon-question',
      },
    ]
   }

  // {
  //   name: 'Master',
  //   class: 'module-nav',
  //   children: [

  //     {
  //       name: 'Course ',
  //       url: '/master/course',
  //       icon: 'icon-notebook',
  //     },




  //     {
  //       name: 'Help and Support',
  //       url: '/master/helpsupport',
  //       icon: 'icon-cursor'
  //     },

  //     {
  //       name: 'Banner',
  //       url: '/master/banner',
  //       icon: 'icon-cursor'
  //     },
  //     {
  //       name: 'Blog',
  //       url: '/master/social',
  //       icon: 'icon-cursor'
  //     },
  //     {
  //       name: 'Company',
  //       url: '/master/company',
  //       icon: 'icon-cursor',
  //     },
  //     {
  //       name: 'Video',
  //       url: '/master/videos',
  //       icon: 'icon-cursor',
  //     },
  //     {
  //       name: 'VideoCourse',
  //       url: '/master/videocourse',
  //       icon: 'icon-cursor',
  //     },
  //   ]
  // },

];


