import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { EncrService } from './encryption.service';


@Injectable()
export class AppService {

  private updatemenuSubject = new Subject<boolean>();


  constructor(
    private encrDecrService: EncrService,

  ) {

   }

  getMenu() {
    return this.updatemenuSubject.asObservable();
  }

  setMenu(status: boolean) {
    this.updatemenuSubject.next(status);
  }
  SetUser(userInfo: any) {
    localStorage.setItem('info', this.encrDecrService.encrypt(userInfo));

  }
  getCurrentUserInfo() {
    let currentUser: any;
    const user = localStorage.getItem('info');
    if (user != null) {
      currentUser = this.encrDecrService.decrypt(user);
    } else {
      return null;
    }
    return currentUser;
  }

  isloggedInUser() {
    if (localStorage.getItem('info') !== null) {
      return true;
    } else {
      return false;
    }
  }

}
