import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from './shared/services/product.service';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {
  constructor(private router: Router,
    public translate: TranslateService,
    public productservice: ProductService,) {
      translate.addLangs(['en', 'mr', 'hi']);
      if (localStorage.getItem('locale')) {
        const browserLang = localStorage.getItem('locale');
        if (browserLang) {
          translate.use(browserLang.match(/en|mr|hi/) ? browserLang : 'mr');
        }
      } else {
        localStorage.setItem('locale', 'mr');
        translate.setDefaultLang('mr');
      }
      //	this.settings = this.appSettings.settings;

      // this.goLive(true);

      if (localStorage.getItem('locale')) {
        const lng = localStorage.getItem('locale');
        if (lng) {
          productservice.setcurrentLanguage(lng);
        }
      }
     }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }
}
