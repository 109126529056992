import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
// import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ToastrService } from 'ngx-toastr';
import { HttpStatusCode, ResponseModel } from '../../shared/responsemodel';
import { ApiDataService } from '../../shared/services/apidata.service';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {
  @ViewChild('blogitem', { static: false }) accordion;
  blogForm: FormGroup;
  dynamicBlogType: any;

  imgBase64src: string | ArrayBuffer;
  imgBase64str: string | ArrayBuffer;

  file = null;
  filename = null;
  fileInfo = null;
  submitted = false;
  fullDescription = false;
  showloader = false;

  blogList = [];
  copyBlogList = [];
  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
  };

  blogTypeList = [
    { id: 1, title: 'General ' },
    { id: 2, title: 'Products' },
    { id: 3, title: 'Health' },
    // { id: 0, title: 'Other ' },

  ];


  blogId = 0;
  htmlContent = '';

  // config1: AngularEditorConfig = {
  //   editable: true,
  //   spellcheck: true,
  //   height: '15rem',
  //   minHeight: '5rem',
  //   placeholder: 'Enter text here...',
  //   translate: 'no',
  //   defaultParagraphSeparator: 'p',
  //   defaultFontName: 'Arial',
  //   toolbarHiddenButtons: [
  //     []
  //   ],
  //   fonts: [
  //     {class: 'arial', name: 'Arial'},
  //     {class: 'times-new-roman', name: 'Times New Roman'},
  //     {class: 'calibri', name: 'Calibri'},
  //     {class: 'comic-sans-ms', name: 'Comic Sans MS'}
   
  //   ],
  //   customClasses: [
  //     {
  //       name: 'quote',
  //       class: 'quote',
  //     },
  //     {
  //       name: 'redText',
  //       class: 'redText'
  //     },
  //     {
  //       name: 'titleText',
  //       class: 'titleText',
  //       tag: 'h1',
  //     },
  //   ]
  // };

  constructor(private fb: FormBuilder,
    private apidataservice: ApiDataService,
    private toastr: ToastrService
    ) {
    this.blogForm = this.fb.group({
      title: ['', [Validators.required]],
      blogid: [],
      description: [''],
      shortdiscription: [''],
      slug: ['', [Validators.required]],
      metad: ['', [Validators.required]],
      video: [''],
      base64: [''],
      metaT: ['', [Validators.required]],
      blogTypeId: ['', [Validators.required]],
      isActive: ['']
    });
  }

  ngOnInit() {
    this.getBlogList();
  }

  getblogtype(blogTypeId: number) {
    return this.blogTypeList.find(m => +m.id === blogTypeId).title;
  }

  get f() {
    return this.blogForm.controls;
  }

  // clear form
  clearForm() {
    this.blogForm.reset();
    this.submitted = false;
    this.imgBase64src = '';
    this.imgBase64str = '';
    this.fileInfo = null;
    this.blogId = 0;
    this.htmlContent = '';
    this.showloader = false;
  }
  pageChange(pageNo: number) {
    this.config.currentPage = pageNo;
  }

  async onselectBannerImages(event: any) {
    this.file = event.target.files[0];
    const file64 =
      this.imgBase64src = await this.apidataservice.toBase64(this.file);
    this.imgBase64str = file64.split(',')[1];
    function formatBytes(bytes: number): string {
      const UNITS = ['Bytes', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
      const factor = 1024;
      let index = 0;
      while (bytes >= factor) {
        bytes /= factor;
        index++;
      }
      return `${parseFloat(bytes.toFixed(2))} ${UNITS[index]}`;
    }
    this.fileInfo = `${this.file['name']} (${formatBytes(this.file.size)})`;
  }

  filter(str: string) {
    str = str.trim();
    if (str !== '') {
      this.blogList = this.copyBlogList.filter(item => {
        const name = String(item.title).toLocaleLowerCase();
        const searchStr = String(str).toLowerCase();
        return (name.includes(searchStr));
      });
    } else {
      this.blogList = this.copyBlogList;
    }
  }
  getBlogList() {
    const url = `Blog/GetBlogsList?BlogTypeId=0&pageSize=0&pageNumber=0`;
    this.apidataservice
      .getData(url)
      .subscribe((response: ResponseModel<any>) => {
        if (response.status === HttpStatusCode.OK) {
          this.blogList = response.data.blogListModels;
          this.copyBlogList = response.data.blogListModels;
          this.config.totalItems = response.data.count;
        }
      });
  }

  // update blog
  public addBlog() {
    const re = '<img';
    const str = this.htmlContent;
    const replaceby = ' <img alt="' + this.blogForm.value.title + '"';
    const newstr = str.replace(re, replaceby);

    this.submitted = true;
    if (this.blogForm.valid) {
      this.showloader = true;
      const data = {
        id: this.blogId,
        title: this.blogForm.value.title,
        description: newstr,
        slug: this.blogForm.value.slug,
        metaDescription: this.blogForm.value.metad,
        shortDiscription: this.blogForm.value.shortdiscription,
        video: this.blogForm.value.video,
        blogTypeId: +this.blogForm.value.blogTypeId,
        imagePath: '',
        base64image: this.imgBase64str,
        tag: this.blogForm.value.metaT,
        isActive: this.blogForm.value.isActive,
      };

      let url = null;
      if (this.blogId === 0) {
        url = `Blog/AddBlogs`;
      } else {
        url = `Blog/UpdateBlogs`;
      }
      this.apidataservice.upload(url, data).subscribe(
        (response: ResponseModel<any>) => {
          if (response.status === HttpStatusCode.OK) {
            if (this.blogId === 0) {
              this.showToast('top-right', 'success', 'Added Successfully');
            } else {
              this.showToast('top-right', 'success', 'Updated Successfully');
            }
            this.showloader = false;
            this.blogId = 0;
            this.clearForm();
            this.getBlogList();
          } else {
            this.showloader = false;
            this.showToast('top-right', 'danger', 'Error Occured...:-(');
          }
        }, (err) => {
          this.showloader = false;
        });

      this.showloader = false;

    }
  }

  editBlog(blog: any) {
    this.accordion.open();
    this.blogId = blog.id;
    this.blogForm.controls.title.setValue(blog.title);
    this.blogForm.controls.description.setValue(blog.description);
    this.htmlContent = blog.description;
    this.blogForm.controls.metad.setValue(blog.metaDescription);
    this.blogForm.controls.shortdiscription.setValue(blog.shortDiscription);
    this.blogForm.controls.video.setValue(blog.video);
    this.blogForm.controls.slug.setValue(blog.slug);
    this.blogForm.controls.metaT.setValue(blog.tag);
    this.blogForm.controls.blogTypeId.setValue(blog.blogTypeId);
    this.blogForm.controls.isActive.setValue(blog.isActive);


  }

  // open dialog
  // openBlogDeleteDialog(blogId: number) {
  //   this.accordion.close();
  //   this.dialogService.open(DeleteBlogDilogComponent, {
  //     context: {
  //       title: '',
  //       id: blogId,
  //     },
  //   }).onClose.subscribe(() => {
  //     this.getBlogList();
  //   });
  // }

  showToast(position, status, message) {
    this.toastr.show(
      status || 'Success',
      `${message}`);
  }
}
