import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppService } from '../app.service';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {
  constructor(
    private appService: AppService
  ) { }

  req: HttpRequest<any>;

  intercept(request: HttpRequest<any>, next: HttpHandler) {

    if (localStorage.getItem('info')) {

      if (request.headers.get('noContentType')) {
        this.req = request.clone({
          setHeaders: {
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${this.appService.getCurrentUserInfo().token}`
          }
        });
      } else {
        this.req = request.clone({
          setHeaders: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Authorization': `Bearer ${this.appService.getCurrentUserInfo().token}`
          }
        });
      }
    } else {
      this.req = request.clone({
        setHeaders: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*'
        }
      });
    }
    return next.handle(this.req);
  }
}
