export enum AdminRole {
  Admin = 1,
  Employee = 2,
  CustomerCareManager = 3,
  CustomerCare = 4,
  Accountant = 5,
  Company = 6,
}

export enum UserType {
  Admin = 1,
  Company = 2,
}
export enum JobType {
  PartTime = 1,
  FullTime = 2,
}

export enum MarkCategory {
    All = 1,
    Excellent = 2,
    VeryGood = 3,
    Good = 4,
    Satisfactory = 5,
}
