import { Injectable } from '@angular/core';

import { Subject } from 'rxjs';
import { IComplaint } from '../model/ticket.model';


@Injectable()
export class ModalDataService {

    private assignTiketsubject = new Subject<IComplaint>();
    private  Refresshsubject = new Subject<IComplaint>();
    private  Refreshdetailssubject = new Subject<IComplaint>();
    private productDetailsubject = new Subject<IComplaint>();
    



    private addTicketsubject=new Subject<[]>();


    constructor() { }

    getassignTiket() {
        return this.assignTiketsubject.asObservable();
    }


    setassignTiket(status: IComplaint) {
        this.assignTiketsubject.next(status);
    }

    getRefreshlist() {
      return this.Refresshsubject.asObservable();
   }

     setRefreshlist(status: IComplaint) {
      this.Refresshsubject.next(status);
  }

  getaddTiket() {
    return this.addTicketsubject.asObservable();
}

  setaddTiket(status: any) {
    this.addTicketsubject.next(status);
 }

 getRefreshdetails() {
  return this.Refreshdetailssubject.asObservable();
}

 setRefreshdetails(status: any) {
  this.Refreshdetailssubject.next(status);
}
getaddOrderdetails() {
  return this.productDetailsubject.asObservable();
}

setaddOrderdetails(status: any) {
  this.productDetailsubject.next(status);
}


}
