import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ProductService } from '../../shared/services/product.service';
import {
 navMaterilaItemsEng,
} from '../../_nav';
import { AppService } from '../../shared/services/app.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ["./default-layout.component.scss"],

})
export class DefaultLayoutComponent {
  public sidebarMinimized = false;
  public navItems = navMaterilaItemsEng;
  currentLang = "en";
  userInfo : any;

  selectedProject = 1;
  // 1 : Material
  // 2 : Constucton
  // 3 : Transfor

  public subscriptions$: Subscription[] = [];


  constructor(private router: Router,
    public translate: TranslateService,
    public product: ProductService,
    private appService: AppService,
    ) { }

  ngOnInit(): void {
    this.subscriptions$.push(
      //------------Subscribe languege change---------------------
      this.product.getcurrentLanguage().subscribe((locale) => {
        this.currentLang = locale;
        localStorage.setItem("locale", locale);
        this.makeMenu();
      })
    );
        this.userInfo = this.appService.getCurrentUserInfo();

  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  logout() {
    localStorage.clear();
    this.router.navigate(['/login']);

  }


  myFunction() {
    document.getElementById("myDropdown")?.classList.toggle("show");
  }
  public changeLanguage(language: string) {
    localStorage.setItem("locale", language);
    this.currentLang = language;
    this.translate.use(language);
    this.product.setcurrentLanguage(language);
    document.getElementById("myDropdown")?.classList.toggle("show");
  }


  selectProject(project: number) {
    this.selectedProject = project;
    this.makeMenu();
  }


  makeMenu(){
// to do navigate to
     this.router.navigateByUrl('dashboard');
  }

}
