import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResponseModel } from '../responsemodel';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiDataService {

 baseUrl = '';

  constructor(private http: HttpClient) {
    this.baseUrl = environment.apiurl;
  }



  getData(url: string): Observable<any> {

    return this.http.get(this.baseUrl + url)
      .pipe(map((response: ResponseModel<any>) => {
        return response;
      }));
  }


  postData(url, data): Observable<any> {
    return this.http.post(this.baseUrl + url, data)
      .pipe(map((response: Response) =>
        response as any));
  }
// For video upload
upload(url, data): Observable<any> {
    return this.http.post(this.baseUrl + url, data, {
    reportProgress: true,
    observe: 'events',
  });
}

  public async toBase64(file: File): Promise<any> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }


  public async getDataAsync(url: string): Promise<any> {
    return await this.http.get(this.baseUrl + url).toPromise();

  }


deleteDoc(url, docInfo): Observable<any> {
  return this.http.post(this.baseUrl + url, docInfo).pipe(map((response: Response) => response as any));
}

}
